"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var IMAGE_AUG_NOTICES_1 = require("./IMAGE_AUG_NOTICES");
var getImageAugNotices = function (images) {
    var imageAugNotices = [];
    IMAGE_AUG_NOTICES_1.IMAGE_AUG_NOTICES.forEach(function (NOTICE) {
        // for each notice type, check all the images, gather all the augBoxs with the same noticeId
        var count = 0;
        images === null || images === void 0 ? void 0 : images.forEach(function (image) {
            var _a;
            if ('augBoxes' in image) {
                var matchedAugBoxes = ((_a = image.augBoxes) === null || _a === void 0 ? void 0 : _a.filter(function (augBox) { return augBox.noticeId === NOTICE.id; })) || [];
                count += matchedAugBoxes.length;
            }
        });
        if (count > 0) {
            imageAugNotices.push({
                id: NOTICE.id,
                message: NOTICE.getNoticeText(count),
            });
        }
    });
    return imageAugNotices;
};
exports.default = getImageAugNotices;
