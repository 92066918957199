"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MatchedRule = void 0;
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var RuleItem_1 = require("@features/ItemCommon/components/RulesAndHistoryModal/MatchedRules/RuleItem");
var sortOrder = ['APPROVE', 'REFUSE', 'MANUAL', 'NOTHING'];
function MatchedRule(_a) {
    var rules = _a.rules, angularJsDependencies = _a.angularJsDependencies, domain = _a.domain, minDate = _a.minDate;
    var t = (0, react_i18next_1.useTranslation)().t;
    var sortedRules = (0, react_1.useMemo)(function () {
        return (rules &&
            __spreadArray([], __read(rules), false).sort(function (a, b) {
                return sortOrder.indexOf(a.result) - sortOrder.indexOf(b.result);
            }));
    }, [rules]);
    return (react_1.default.createElement("div", { className: "rules-modal cont" },
        react_1.default.createElement("div", { className: "rules-modal__header" }, t('itemLog:ITEM.RULES_MODAL_TITLE')),
        sortedRules &&
            sortedRules.map(function (rule, ruleIndex) { return (react_1.default.createElement(RuleItem_1.RuleItem, { key: ruleIndex, rule: rule, domain: domain, angularJsDependencies: angularJsDependencies, minDate: minDate })); }),
        !rules && react_1.default.createElement("div", { className: "rules-modal__no-rule-text" }, t('itemLog:RULES_MODAL_NO_RULE_TEXT'))));
}
exports.MatchedRule = MatchedRule;
