"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
function SideBar(_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, children = _a.children, title = _a.title;
    var handleKeyDown = (0, react_1.useCallback)(function (event) {
        if (event.key === 'Escape') {
            setIsOpen(false);
        }
    }, []);
    (0, react_1.useEffect)(function () {
        document.addEventListener('keydown', handleKeyDown);
        return function () {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    var scrollbarSize = window.innerWidth - document.body.offsetWidth;
    return (react_1.default.createElement(react_1.default.Fragment, null, isOpen && (react_1.default.createElement("div", { className: "custom-sidebar", onClick: function () { return setIsOpen(false); } },
        react_1.default.createElement("style", null, "\n            body, .eva-navbar-primary {\n              overflow: hidden;\n              margin-right: ".concat(scrollbarSize, "px;\n            }\n          ")),
        react_1.default.createElement("div", { className: "custom-sidebar-content", onClick: function (e) { return e.stopPropagation(); } },
            react_1.default.createElement("div", { className: "custom-sidebar-head" },
                title && react_1.default.createElement("h3", null, title),
                react_1.default.createElement("i", { className: "implioicons-close custom-sidebar-close", onClick: function () { return setIsOpen(false); } })),
            children)))));
}
exports.default = SideBar;
