(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment', {
      url: '/a/:domain',
      abstract: true,
      resolve: {
        authenticate: ['CurrentUser', '$stateParams', function(CurrentUser, $stateParams) {
          // Track the team for this user the first time a user enters an assignment.
          CurrentUser.trackTeam($stateParams.domain);
        }]
      }
    }).state('app.assignment.search', {
      url: '/search?text&cursor&perPage&sort&order&filters&status&type',
      data: {
        title: 'PAGE_TITLE.SEARCH'
      },
      params: {
        showSearch: true,
        // sort: 'date',
        // order: 'desc'
      },
      views: {
        'content@app': {
          template: '<eva-search></eva-search>',
          controller: 'SearchCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();

