/**
 * parseBlangExpression
 * @Description: Parse blang expression to an array of rules, Split by the conjunction word "AND" and "OR"
 * @variable
 * rules: Get the rules array
 * containsOR: the blang contains "OR" or not
 * containsLENGTH: the blang contains "LENGTH" or not
 * isUsingCONTAINSWithOccurrences: the blang editor contains CONTAINS [x,y] or CONTAINS {unique=true} [x,y]
 */

(function() {
  'use strict';

  angular.module('eva2-angular').service('parseBlangExpression', function(ApiService, analyzeRule) {
    'ngInject';

    return (domain, item) => {
      return ApiService.validateBlangRule(domain, item).then((data) => {
        const result = {
          hasComment: data.hasComment,
          analyzedRule: analyzeRule(data.parsedRule)
        };
        return result;
      });
    };
  });
})();
