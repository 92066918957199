import Rules from '@/react-app/features/Rules';
import store from '@/redux/store';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('AdminFiltersCtrl', AdminFiltersCtrl);


  function AdminFiltersCtrl($scope, $stateParams, $controller, $timeout, $state, UserPermissionManager, CurrentUser) {
    $controller('FilterBaseCtrl', {$scope: $scope});
    const vm = this;
    vm.domain = $stateParams.domain;
    const root = createRoot(document.querySelector('#filtersPage'));
    vm.domain = $stateParams.domain;

    const renderRules = () => {
      $timeout(() => {
        root.render(
          <Provider store={store}>
            <Rules  domain={$stateParams.domain}
              angularJsDependencies={
                {
                  href: $state.href,
                  userPermissionManager: UserPermissionManager,
                  go: $state.go,
                }
              }
              currentUser={CurrentUser}
            />
          </Provider>
        );
      });
    };

    vm.$onInit = () => {
      renderRules();
    };
    
    $scope.$on('$destroy', ()=> {
      root.unmount();
    });
  }
})();
