import DictionaryEdit from '@/react-app/features/Dictionaries/DictionaryEdit';
import store from '@store';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('AdminDictionaryEditCtrl', AdminDictionaryEditCtrl);

  function AdminDictionaryEditCtrl($stateParams, $state, $scope, $timeout, UserPermissionManager) {
    const vm = this;

    const root = createRoot(document.querySelector('#dictionary-edit'));

    const renderDictionaryItemsEditor = () => {
      $timeout(() => {
        root.render(
          <Provider store={store}>
            <DictionaryEdit  domain={$stateParams.domain}
              angularJsDependencies={
                {
                  href: $state.href,
                  userPermissionManager: UserPermissionManager,
                  go: $state.go,
                }
              }
              name={$stateParams.name}
            />
          </Provider>
        );
      });
    };

    vm.$onInit = function() {
      renderDictionaryItemsEditor();
    };

    $scope.$on('$destroy', ()=> {
      root.unmount();
    });
  }
})();
