"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceLessThanSignWithHtmlEntity = void 0;
// to prevent sanitize-html from removing the text after < sign (There is no option or fix for this in sanitize-html library)
var replaceLessThanSignWithHtmlEntity = function (text) {
    // matches any valid opening or closing HTML tag.
    var htmlTagRegex = /<\/?[a-zA-Z][^>]*>/g;
    // Split the text by '<' and '>'
    var segments = text.split(/(<[^>]*>)/);
    // Process each segment
    for (var i = 0; i < segments.length; i++) {
        if (!htmlTagRegex.test(segments[i])) {
            segments[i] = segments[i].replace(/</g, '&lt;');
        }
    }
    // Rejoin the segments
    return segments.join('');
};
exports.replaceLessThanSignWithHtmlEntity = replaceLessThanSignWithHtmlEntity;
