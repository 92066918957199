"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyDown = void 0;
var react_1 = require("react");
// ref should be a focusable element otherwise this hooks won't work
var useKeyDown = function (ref, keys, callback) {
    var escapeOrTabPress = function (_a) {
        var key = _a.key;
        if (keys.some(function (k) { return k === key; })) {
            callback();
        }
    };
    (0, react_1.useEffect)(function () {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.addEventListener('keydown', escapeOrTabPress);
        return function () {
            var _a;
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', escapeOrTabPress);
        };
    });
};
exports.useKeyDown = useKeyDown;
