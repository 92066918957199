"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortCategories = exports.isPartOfChildrens = exports.getOpenMode = exports.getValueDepth = exports.TreeOpenMode = void 0;
var TreeOpenMode;
(function (TreeOpenMode) {
    TreeOpenMode[TreeOpenMode["NO_DATA"] = 0] = "NO_DATA";
    TreeOpenMode[TreeOpenMode["TREE"] = 1] = "TREE";
    TreeOpenMode[TreeOpenMode["ONE_LEVEL_DROPDOWN"] = 2] = "ONE_LEVEL_DROPDOWN";
    TreeOpenMode[TreeOpenMode["TWO_LEVEL_DROPDOWN"] = 3] = "TWO_LEVEL_DROPDOWN";
})(TreeOpenMode || (exports.TreeOpenMode = TreeOpenMode = {}));
function getValueDepth(data) {
    var level = 0;
    if (Array.isArray(data) && data.length > 0) {
        level = 1;
        data.forEach(function (item) {
            if (!('subCategories' in item) || !(item === null || item === void 0 ? void 0 : item.subCategories))
                return;
            if ('subCategories' in item) {
                var depth = getValueDepth(item.subCategories) + 1;
                level = Math.max(depth, level);
            }
        });
    }
    return level;
}
exports.getValueDepth = getValueDepth;
function getOpenMode(data) {
    var depth = getValueDepth(data);
    switch (depth) {
        case 0:
            return TreeOpenMode.NO_DATA;
        case 1:
            return TreeOpenMode.ONE_LEVEL_DROPDOWN;
        case 2:
            return TreeOpenMode.TWO_LEVEL_DROPDOWN;
        default:
            return TreeOpenMode.TREE;
    }
}
exports.getOpenMode = getOpenMode;
// Check if tempcategory is part of the subcategories recursively
var isPartOfChildrens = function (category, tempCategory) {
    if (category.subCategories) {
        return category.subCategories.some(function (subCategory) {
            if (subCategory.categoryId === tempCategory.categoryId) {
                return true;
            }
            return (0, exports.isPartOfChildrens)(subCategory, tempCategory);
        });
    }
    return false;
};
exports.isPartOfChildrens = isPartOfChildrens;
function sortCategories(categories) {
    return __spreadArray([], __read(categories), false).sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        return 1;
    });
}
exports.sortCategories = sortCategories;
