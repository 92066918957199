import ngRedux from 'ng-redux';
angular.module('eva2-angular.headers', []);
angular.module('eva2-angular.storage', []);
angular.module('eva2-angular.user', [
  'eva2-angular.headers',
  'eva2-angular.storage'
]);
angular.module('eva2-angular', [
  'ngSentry',
  'config',
  'ui.router',
  'ngSanitize',
  'ngAnimate',
  'ui.bootstrap',
  'ui.codemirror',
  'ngTable',
  'angular-ladda',
  'ajoslin.promise-tracker',
  'ngclipboard',
  'eva2-angular.headers',
  'eva2-angular.storage',
  'eva2-angular.user',
  'angucomplete-alt',
  'vcRecaptcha',
  'pascalprecht.translate',
  'angular-page-visibility',
  ngRedux
]);
