"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFilterNameById = exports.implioFilters = exports.implioFilterGroups = exports.filterDateKeys = exports.filterKey = exports.filterType = void 0;
exports.filterType = {
    INPUT: 'input',
    DATEPICKER: 'datepicker',
    NUMBER: 'number',
    SELECT: 'select',
    MULTISELECT: 'multiselect',
    SEPARATOR: 'separator',
};
exports.filterKey = {
    itemId: 'document.id',
    title: 'document.content.title',
    body: 'document.content.body',
    categoryName: 'document.content.category.name',
    typeName: 'document.content.type.name',
    price: 'document.content.price.amount',
    createdAt: 'metadata.created',
    updatedAt: 'metadata.updated',
    userId: 'document.user.id',
    userName: 'document.user.name',
    userEmail: 'document.user.emailAddresses',
    userPhone: 'document.user.phoneNumbers',
    userIp: 'document.location.ipAddress',
    agentId: 'metadata.manualResults.agentId',
    decisionName: 'metadata.decisions.name',
    moderatedAt: 'metadata.manualResults.timestamp',
    feedbackName: 'metadata.feedback.name',
    filterName: 'metadata.automaticResults.filters.name',
    automaticResult: 'metadata.automaticResults.result',
    manualResult: 'metadata.manualResults.result',
    clientResult: 'metadata.clientResults.result',
    result: 'metadata.result',
};
exports.filterDateKeys = [exports.filterKey.createdAt, exports.filterKey.updatedAt, exports.filterKey.moderatedAt];
exports.implioFilterGroups = [
    {
        name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.ITEM_DETAILS',
        icon: 'bounding-box',
        id: 'documentDetails',
        type: exports.filterType.SEPARATOR,
        filters: [
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.ITEM_ID',
                id: exports.filterKey.itemId,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.ITEM_ID_PLACEHOLDER',
                type: exports.filterType.INPUT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.TEXT',
                id: 'text', // special case
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.TEXT_PLACEHOLDER',
                type: exports.filterType.INPUT,
                hideChip: true,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.TITLE',
                id: exports.filterKey.title,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.TITLE_PLACEHOLDER',
                type: exports.filterType.INPUT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.BODY',
                id: exports.filterKey.body,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.BODY_PLACEHOLDER',
                type: exports.filterType.INPUT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.CATEGORY',
                id: exports.filterKey.categoryName,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.CATEGORY_PLACEHOLDER',
                type: exports.filterType.INPUT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.TYPE',
                id: exports.filterKey.typeName,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.TYPE_PLACEHOLDER',
                type: exports.filterType.INPUT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.CREATED_AT',
                id: exports.filterKey.createdAt,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.CREATED_AT_PLACEHOLDER',
                type: exports.filterType.DATEPICKER,
                dateMaxNow: true,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.UPDATED_AT',
                id: exports.filterKey.updatedAt,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.UPDATED_AT_PLACEHOLDER',
                type: exports.filterType.DATEPICKER,
                dateMaxNow: true,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.PRICE',
                id: exports.filterKey.price,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.PRICE_PLACEHOLDER',
                type: exports.filterType.NUMBER,
            },
        ],
    },
    {
        name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_DETAILS',
        icon: 'user',
        id: 'userDetails',
        type: exports.filterType.SEPARATOR,
        filters: [
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_ID',
                id: exports.filterKey.userId,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_ID_PLACEHOLDER',
                type: exports.filterType.INPUT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_NAME',
                id: exports.filterKey.userName,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_NAME_PLACEHOLDER',
                type: exports.filterType.INPUT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_EMAIL',
                id: exports.filterKey.userEmail,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_EMAIL_PLACEHOLDER',
                type: exports.filterType.INPUT,
                regex: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$',
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_PHONE',
                id: exports.filterKey.userPhone,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_PHONE_PLACEHOLDER',
                type: exports.filterType.INPUT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_IP',
                id: exports.filterKey.userIp,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.USER_IP_PLACEHOLDER',
                type: exports.filterType.INPUT,
            },
        ],
    },
    {
        name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.MODERATION_DETAILS',
        icon: 'profanity',
        id: 'moderationDetails',
        type: exports.filterType.SEPARATOR,
        filters: [
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.AGENT_ID',
                id: exports.filterKey.agentId,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.AGENT_ID_PLACEHOLDER',
                type: exports.filterType.INPUT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.MODERATED_AT',
                id: exports.filterKey.moderatedAt,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.MODERATED_AT_PLACEHOLDER',
                type: exports.filterType.DATEPICKER,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.REFUSAL_REASON',
                id: exports.filterKey.decisionName,
                items: [],
                type: exports.filterType.SELECT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.FEEDBACK',
                id: exports.filterKey.feedbackName,
                items: [],
                type: exports.filterType.MULTISELECT,
            },
            {
                name: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.FILTER_NAME',
                id: exports.filterKey.filterName,
                placeholder: 'SEARCH.SEARCH_QUERY_BUILDER.FILTERS.FILTER_NAME_PLACEHOLDER',
                type: exports.filterType.SELECT,
            },
        ],
    },
];
exports.implioFilters = [];
var getFilterNameById = function (id) { var _a; return ((_a = exports.implioFilters.find(function (filter) { return filter.id === id; })) === null || _a === void 0 ? void 0 : _a.name) || id; };
exports.getFilterNameById = getFilterNameById;
