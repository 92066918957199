"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubmitDecisionApiData = exports.parseSelectedFeedback = void 0;
var getCurrentQueueNameAndId_1 = require("./getCurrentQueueNameAndId");
//  TO DO when working on selectedFeedback in Queue Screen
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var parseSelectedFeedback = function (job) {
    // if (Array.isArray(job.selectedFeedback) && job.selectedFeedback.length > 0) {
    //   return job.selectedFeedback.map((item) => ({ id: item.id, name: item.label }));
    // }
    return [];
};
exports.parseSelectedFeedback = parseSelectedFeedback;
var getSubmitDecisionApiData = function (job) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var decisionType = (_b = (_a = job === null || job === void 0 ? void 0 : job.selectedDecision) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.toUpperCase();
    var ad = {
        current: { content: (_c = job.ad) === null || _c === void 0 ? void 0 : _c.current.content, user: (_d = job.ad) === null || _d === void 0 ? void 0 : _d.current.user, customerSpecific: (_e = job.ad) === null || _e === void 0 ? void 0 : _e.customerSpecific },
    };
    // if (ad.current.content?.images) {
    //   ad.current.content.images = ad.current.content.images.map((image) => {
    //     return { src: image.src };
    //   });
    // }
    // to do verify if this is needed because we are not adding playing and error to the video object
    // enrichVideosInfo is treated in job.ts
    // if (ad.current.content?.videos) {
    // ad.current.content.videos = ad.current.content.videos.map((video) => {
    //   if ('deleted' in video) {
    //     return { src: video.src, deleted: video.deleted };
    //   }
    //   return { src: '', deleted: true };
    // });
    // if (job?.ad?.videosInfo?.content) {
    //   const enrichedVideosInfoContent = enrichVideosInfo(ad.current.content?.videos, job?.ad?.videosInfo?.content);
    //   //  Ask the backend what should what is the shape of the object may be
    //   // the current shape is wrong and we should use job.videosInfo properties instead of ad.current.content.videos
    //   // TO DO verify if in production if we are sending src props in ad.current.content.videos
    //   // TO DO find example where deleted is true
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   ad.current.content.videos = enrichedVideosInfoContent;
    // }
    // }
    var feedbackData = (0, exports.parseSelectedFeedback)(job);
    var refusalReasons = ((_f = job.selectedDecision) === null || _f === void 0 ? void 0 : _f.reason) ? [job.selectedDecision.reason] : null;
    var mostRecentHistory = (_h = (_g = job.ad) === null || _g === void 0 ? void 0 : _g.history) === null || _h === void 0 ? void 0 : _h[0];
    var _o = __read((0, getCurrentQueueNameAndId_1.getCurrentQueueNameAndId)(mostRecentHistory), 2), currentQueueName = _o[0], currentQueueId = _o[1];
    var status = ((_l = (_k = (_j = job.ad) === null || _j === void 0 ? void 0 : _j.current) === null || _k === void 0 ? void 0 : _k.meta) === null || _l === void 0 ? void 0 : _l.status) || '';
    // TO DO when working on DEFER decision type in Queue Screen
    // if (decisionType === 'DEFER' && job.routingQueueObj) {
    //   decisionData.routingQueue = job.routingQueueObj.id;
    //   decisionData.routingQueueName = job.routingQueueObj.label;
    // }
    var decisionData = {
        id: (_m = job.ad) === null || _m === void 0 ? void 0 : _m.id,
        decision: decisionType,
        ad: ad,
        feedback: feedbackData,
        decisions: refusalReasons,
        queueName: ['reviewable', 'deferred'].includes(status) ? currentQueueName : undefined,
        queueId: ['reviewable', 'deferred'].includes(status) ? currentQueueId : undefined,
    };
    return decisionData;
};
exports.getSubmitDecisionApiData = getSubmitDecisionApiData;
