(function() {
  'use strict';
  angular.module('eva2-angular').service('getUserBySessionToken', function(ApiService) {
    /**
     * This function returns a resolved promise when local storage contains the authenticated flag,
     * otherwise returns a rejected promise when session doesn't exist or invalid
     * @returns {promise}
     */

    function getUserBySessionToken() {
      // const hasSessionBeforeLogin = !!storage.getItem('authenticated');
      
      // if (hasSessionBeforeLogin) {
      return ApiService.getUser();
      // }
      // return $q.reject();
    }

    return getUserBySessionToken;
  });
})();
