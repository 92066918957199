import { unsafeCharacters } from '@common/constants';
import _ from 'lodash';
import apiService from '@common/services/apiService';
import { handleAuthLogout } from '@/react-app/common/utils/handleAuth0redirect';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('CreateTeamCtrl', CreateTeamCtrl);

  function CreateTeamCtrl($scope, $state, CurrentUser, Notifier, $stateParams, goToDefault,
    TestRegex, ApiService, tFilter, $timeout, checkTeamCreationStatus) {
    'ngInject';
    const vm = this;

    const regions = [{
      id: 'europe',
      label: tFilter('CREATE_TEAM.REGIONS_LABEL.EUROPE')
    }, {
      id: 'south-america',
      label: tFilter('CREATE_TEAM.REGIONS_LABEL.LATIN_AMERICA')
    }, {
      id: 'north-america',
      label: tFilter('CREATE_TEAM.REGIONS_LABEL.NORTH_AMERICA')
    }, {
      id: 'asia-pacific',
      label: tFilter('CREATE_TEAM.REGIONS_LABEL.ASIA')
    }, {
      id: 'africa',
      label: tFilter('CREATE_TEAM.REGIONS_LABEL.AFRICA')
    }, {
      id: 'middle-east',
      label: tFilter('CREATE_TEAM.REGIONS_LABEL.MIDDLE_EAST')
    }, {
      id: 'oceania',
      label: tFilter('CREATE_TEAM.REGIONS_LABEL.OCEANIA')
    }];
    
    const regionLabel = tFilter('CREATE_TEAM.SERVICE_REGION_LABEL');
    const regionTooltip = tFilter('CREATE_TEAM.SERVICE_REGION_TOOLTIP');

    
    let isAccountCreatedPromise;

    vm.regions = regions;
    vm.regionLabel = regionLabel;
    vm.regionTooltip = regionTooltip;
    vm.MAX_NAME_LENGTH = 30;
    vm.MAX_COMPANY_LENGTH = vm.MAX_NAME_LENGTH;

    vm.signup = {};
    vm.signup.selectedRegion = vm.regions[0];

    vm.loading = false;
    $scope.signupButtonMessage = tFilter('CREATE_TEAM.CREATE_TEAM_BTN' );
    $scope.unsafeCharacters=unsafeCharacters;
    
    ApiService.getUserLocation().then((data) => {
      const inferredRegionId = data && data.continentId;
      const inferredRegion = _.find(vm.regions, (region) => region.id === inferredRegionId);
      if (inferredRegion) {
        vm.signup.selectedRegion = inferredRegion;
      }
    });

    const onTeamCreationSuccessGetUserData = async () =>{      
      // send event to Google Tag Manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmission'
      });
      const currentUser =await ApiService.getUser();
      CurrentUser.logIn(currentUser);
      vm.loading = false;
      goToDefault({showIntroduction: true});
      
    };

    const onTeamCreationFailure = (err) => {
      $scope.teamIsBeingCreated = false;
      if(err){
        Notifier.display(tFilter('CREATE_TEAM.ERROR.TEAM_CREATION_ERROR'), {type: 'error', ttl: 8000}, err);
      } else {
        Notifier.display(tFilter('CREATE_TEAM.ERROR.TEAM_CREATION_ERROR'), {type: 'error', ttl: 8000});
      }
    };

    function onSignUpSuccess(createdTeamData) {
      console.log('createdTeamData', createdTeamData);
      
      const domain = createdTeamData?.data?.siteAccount?.domain;
      console.log('domain', domain);
  
      checkTeamCreationStatus(domain,20, onTeamCreationSuccessGetUserData, onTeamCreationFailure, createdTeamData);
    }



    function onSignUpFailure(err) {
      $scope.teamIsBeingCreated = false;
      $scope.error = true;
      
      if (!err) {
        return;
      }

      const nonAllowedUserDetails = err.status && (err.status === 409 || err.status === 400);
      if (nonAllowedUserDetails) {
        if (err.data.context === 'region') {
          // Why the UI is not reflecting vm.regionError change?
          vm.regionError = err.data.message;
          Notifier.display(err.data.message, {type: 'error', ttl: 8000});
        }
        Notifier.display('All the fields are required', {type: 'error', ttl: 8000});
        return;
      }

      let msg = tFilter('CREATE_TEAM.ERROR.SERVER_ERROR');

      if (err.status === -1) {
        msg = tFilter('CREATE_TEAM.ERROR.CONNECT_ERROR');
      }

      Notifier.display(msg, {type: 'error', ttl: 8000});
    }

    const setLoadingToFalse = function() {
      // Why the UI is not reflecting vm.loading change?
      vm.loading = false;
    };

    $scope.submit = function() {
      vm.loading = true;
      $scope.teamIsBeingCreated = true;
      const data ={
        region: vm.signup.selectedRegion.id,
        teamName: vm.signup.teamname,
        company: vm.signup.company,
      };
      
      apiService.createTeam(null, data).then(onSignUpSuccess).catch(onSignUpFailure).finally(setLoadingToFalse);
    };

    vm.updateRegion = (item) => {
      if (item !== vm.signup.selectedRegion) {
        vm.signup.selectedRegion = item;
        vm.regionError = null;
      }
    };

    vm.filterUnsafeCharactersOnBlur = (e)=> {
      let safe = e.target.value.replace(new RegExp(`[${unsafeCharacters}]`, 'g'), '');
      vm.signup[e.target.name]=safe;
    };

    $scope.$watch('teamIsBeingCreated', function(newVal, oldVal) {
      if (oldVal !== newVal && newVal) {
        $scope.signupButtonMessage = tFilter('CREATE_TEAM.TEAM_CREATION_TEXT');
      }
      if (oldVal !== newVal && newVal === false) {
        $scope.signupButtonMessage = tFilter('CREATE_TEAM.CREATE_TEAM_BTN');
      }
    });

    $scope.$on('$destroy', function(){
      if (isAccountCreatedPromise) {
        $timeout.cancel(isAccountCreatedPromise);
      }
    });

    vm.switchAccount = function() {
      handleAuthLogout();
    };
  }
})();
