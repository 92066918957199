const angular = require('angular');
require('/src/intercom.js');

// add npm dependencies
require('angular-bootstrap');
require('bootstrap');
window.CodeMirror = require('codemirror/lib/codemirror');
require('codemirror/addon/mode/simple');
require('codemirror/addon/edit/matchbrackets');
require('codemirror/addon/display/placeholder');
require('besedo-angular-ui-codemirror');
require('@uirouter/angularjs');
require('ng-table');
require('angular-promise-tracker');
require('angular-ladda');
if (!angular.lowercase) {
  angular.lowercase = str => angular.isString(str) ? str.toLowerCase() : str;
}
require('textangular/dist/textAngular-sanitize');
require('angular-animate');
require('ngclipboard');
require('slick-carousel');
require('angucomplete-alt');
require('angular-recaptcha');
require('besedo-select2');
require('moment-timezone');
require('angular-translate');
require('angular-translate-interpolation-messageformat');
require('intersection-observer');
require('@os33/angular_page_visibility');

require('./css/app.less');


function requireAll(r) {r.keys().forEach(r);}

// config.js
angular.module('config', []);
angular.module('config').constant('BASE_URL', BASE_URL);
angular.module('config').constant('DEPLOY_INFO', DEPLOY_INFO);
angular.module('config').constant('DEBUG_ENABLED', DEBUG_ENABLED);
angular.module('config').constant('INTERCOM_ID', INTERCOM_ID);
angular.module('config').constant('RECAPTCHA_KEY', RECAPTCHA_KEY);
angular.module('config').constant('EXTERNAL_API_URL', EXTERNAL_API_URL);

// configSentry.js
require('./app/configSentry');

require('./app/setup');
requireAll(require.context('./app/service/', true, /\.js$/));
requireAll(require.context('./app/model/', true, /\.js$/));
requireAll(require.context('./app/directive/', true, /\.js$/));
requireAll(require.context('./app/filter/', true, /\.js$/));
requireAll(require.context('./app/partial/', true, /\.js$/));
requireAll(require.context('./app/partial/', true, /route\.js$/));
requireAll(require.context('./app/component/', true, /\.js$/));
requireAll(require.context('./app/enum/', true, /\.js$/));
requireAll(require.context('./assets/test-images/', true));
requireAll(require.context('./assets/email/', true));
require('./app/config');
require('./app/run');
