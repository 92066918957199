(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('Auth0ErrorPageCtrl', Auth0ErrorPageCtrl);

  function Auth0ErrorPageCtrl( tFilter) {
    'ngInject';
    let vm = this;
    const errorMsg = tFilter('AUTH0_ERROR_PAGE.MSG');
    vm.errorMsg = errorMsg;
  }
})();
