import { createRoot } from 'react-dom/client';
import React from 'react';
import { ReportDownload } from '@features/StatisticPage/components/ReportDownload';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider
      .state('app.assignment.overview', {
        url: '/overview',
        abstract: true,
        data: {
          nav: {
            secondary: [{
              name: 'NAV.SUB.STATISTICS',
              state: 'app.assignment.overview.statistics',
              allStatesUnderNavTab: [
                'app.assignment.overview.statistics'
              ]
            }, {
              name: 'NAV.SUB.ITEM_LOG',
              state: 'app.assignment.overview.recentItems',
              allStatesUnderNavTab: [
                'app.assignment.overview.recentItems'
              ]
            }]
          }
        },
        views: {
          'content@app': {
            template: require('@partial/layouts/layouts2.html')
          }
        }
      })
      .state('app.assignment.overview.statistics', {
        url: '/statistics?from&to&timeZone',
        data: {
          title: 'PAGE_TITLE.DASHBOARD'
        },
        params: {
          showIntroduction: false,
          inviteParams: {
            inviteTeamName: ''
          },
          from: moment().subtract(6, 'days').startOf('day').format(),
          to: moment().subtract(0, 'days').endOf('day').format()
        },
        views: {
          'content@app': {
            template: require('@partial/overview/statistics/statistics.html'),
            controller: 'StatisticsController',
            controllerAs: 'vm'
          }
        }
      })
      .state('app.assignment.overview.recentItems', {
        url: '/items?status&id&order&sort&cursor&perPage&from&to&type',
        data: {
          title: 'PAGE_TITLE.RECENT_ITEMS'
        },
        views: {
          'content@app': {
            template: require('@partial/overview/recentItems/recentItems.html'),
            controller: 'RecentItemsController',
            controllerAs: 'vm'
          }
        }
      });
    $stateProvider.state('app.assignment.reports', {
      url: '/reports/:fileName',
      data: {
        title: 'PAGE_TITLE.RECENT_ITEMS'
      },
      views: {
        'content@app': {
          template: '<div id="reportDownload"></div>',
          controller: ($stateParams, $state) => {
            const root = createRoot(document.querySelector('#reportDownload'));
            root.render(<ReportDownload domain={$stateParams.domain} file={$stateParams.fileName}
              angularJsDependencies={{ go: $state.go }} />);
          },
          controllerAs: 'vm'
        }
      }
    });
  }
})();
