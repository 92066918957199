"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.importLocale = void 0;
/** *
 We are not allowed to use dynamic imports in Webpack.
 Critical dependency: the request of a dependency is an expression
 We use this function to import the locale dynamically.
 ** */
var importLocale = function (userLocale) { return __awaiter(void 0, void 0, void 0, function () {
    var localeToSet;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(userLocale === 'af')) return [3 /*break*/, 2];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/af/index.js")); })];
            case 1:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 2:
                if (!(userLocale === 'ar')) return [3 /*break*/, 4];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ar/index.js")); })];
            case 3:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 4:
                if (!(userLocale === 'ar-DZ')) return [3 /*break*/, 6];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ar-DZ/index.js")); })];
            case 5:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 6:
                if (!(userLocale === 'ar-EG')) return [3 /*break*/, 8];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ar-EG/index.js")); })];
            case 7:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 8:
                if (!(userLocale === 'ar-MA')) return [3 /*break*/, 10];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ar-MA/index.js")); })];
            case 9:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 10:
                if (!(userLocale === 'ar-SA')) return [3 /*break*/, 12];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ar-SA/index.js")); })];
            case 11:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 12:
                if (!(userLocale === 'ar-TN')) return [3 /*break*/, 14];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ar-TN/index.js")); })];
            case 13:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 14:
                if (!(userLocale === 'az')) return [3 /*break*/, 16];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/az/index.js")); })];
            case 15:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 16:
                if (!(userLocale === 'be')) return [3 /*break*/, 18];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/be/index.js")); })];
            case 17:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 18:
                if (!(userLocale === 'be-tarask')) return [3 /*break*/, 20];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/be-tarask/index.js")); })];
            case 19:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 20:
                if (!(userLocale === 'bg')) return [3 /*break*/, 22];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/bg/index.js")); })];
            case 21:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 22:
                if (!(userLocale === 'bn')) return [3 /*break*/, 24];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/bn/index.js")); })];
            case 23:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 24:
                if (!(userLocale === 'bs')) return [3 /*break*/, 26];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/bs/index.js")); })];
            case 25:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 26:
                if (!(userLocale === 'ca')) return [3 /*break*/, 28];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ca/index.js")); })];
            case 27:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 28:
                if (!(userLocale === 'cs')) return [3 /*break*/, 30];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/cs/index.js")); })];
            case 29:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 30:
                if (!(userLocale === 'cy')) return [3 /*break*/, 32];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/cy/index.js")); })];
            case 31:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 32:
                if (!(userLocale === 'da')) return [3 /*break*/, 34];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/da/index.js")); })];
            case 33:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 34:
                if (!(userLocale === 'de')) return [3 /*break*/, 36];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/de/index.js")); })];
            case 35:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 36:
                if (!(userLocale === 'de-AT')) return [3 /*break*/, 38];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/de-AT/index.js")); })];
            case 37:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 38:
                if (!(userLocale === 'el')) return [3 /*break*/, 40];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/el/index.js")); })];
            case 39:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 40:
                if (!(userLocale === 'en-AU')) return [3 /*break*/, 42];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/en-AU/index.js")); })];
            case 41:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 42:
                if (!(userLocale === 'en-CA')) return [3 /*break*/, 44];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/en-CA/index.js")); })];
            case 43:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 44:
                if (!(userLocale === 'en-GB')) return [3 /*break*/, 46];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/en-GB/index.js")); })];
            case 45:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 46:
                if (!(userLocale === 'en-IE')) return [3 /*break*/, 48];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/en-IE/index.js")); })];
            case 47:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 48:
                if (!(userLocale === 'en-IN')) return [3 /*break*/, 50];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/en-IN/index.js")); })];
            case 49:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 50:
                if (!(userLocale === 'en-NZ')) return [3 /*break*/, 52];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/en-NZ/index.js")); })];
            case 51:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 52:
                if (!(userLocale === 'en-US')) return [3 /*break*/, 54];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/en-US/index.js")); })];
            case 53:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 54:
                if (!(userLocale === 'en-ZA')) return [3 /*break*/, 56];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/en-ZA/index.js")); })];
            case 55:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 56:
                if (!(userLocale === 'eo')) return [3 /*break*/, 58];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/eo/index.js")); })];
            case 57:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 58:
                if (!(userLocale === 'es')) return [3 /*break*/, 60];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/es/index.js")); })];
            case 59:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 60:
                if (!(userLocale === 'et')) return [3 /*break*/, 62];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/et/index.js")); })];
            case 61:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 62:
                if (!(userLocale === 'eu')) return [3 /*break*/, 64];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/eu/index.js")); })];
            case 63:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 64:
                if (!(userLocale === 'fa-IR')) return [3 /*break*/, 66];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/fa-IR/index.js")); })];
            case 65:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 66:
                if (!(userLocale === 'fi')) return [3 /*break*/, 68];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/fi/index.js")); })];
            case 67:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 68:
                if (!(userLocale === 'fr')) return [3 /*break*/, 70];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/fr/index.js")); })];
            case 69:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 70:
                if (!(userLocale === 'fr-CA')) return [3 /*break*/, 72];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/fr-CA/index.js")); })];
            case 71:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 72:
                if (!(userLocale === 'fr-CH')) return [3 /*break*/, 74];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/fr-CH/index.js")); })];
            case 73:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 74:
                if (!(userLocale === 'fy')) return [3 /*break*/, 76];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/fy/index.js")); })];
            case 75:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 76:
                if (!(userLocale === 'gd')) return [3 /*break*/, 78];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/gd/index.js")); })];
            case 77:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 78:
                if (!(userLocale === 'gl')) return [3 /*break*/, 80];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/gl/index.js")); })];
            case 79:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 80:
                if (!(userLocale === 'gu')) return [3 /*break*/, 82];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/gu/index.js")); })];
            case 81:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 82:
                if (!(userLocale === 'he')) return [3 /*break*/, 84];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/he/index.js")); })];
            case 83:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 84:
                if (!(userLocale === 'hi')) return [3 /*break*/, 86];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/hi/index.js")); })];
            case 85:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 86:
                if (!(userLocale === 'hr')) return [3 /*break*/, 88];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/hr/index.js")); })];
            case 87:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 88:
                if (!(userLocale === 'ht')) return [3 /*break*/, 90];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ht/index.js")); })];
            case 89:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 90:
                if (!(userLocale === 'hu')) return [3 /*break*/, 92];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/hu/index.js")); })];
            case 91:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 92:
                if (!(userLocale === 'hy')) return [3 /*break*/, 94];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/hy/index.js")); })];
            case 93:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 94:
                if (!(userLocale === 'id')) return [3 /*break*/, 96];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/id/index.js")); })];
            case 95:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 96:
                if (!(userLocale === 'is')) return [3 /*break*/, 98];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/is/index.js")); })];
            case 97:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 98:
                if (!(userLocale === 'it')) return [3 /*break*/, 100];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/it/index.js")); })];
            case 99:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 100:
                if (!(userLocale === 'it-CH')) return [3 /*break*/, 102];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/it-CH/index.js")); })];
            case 101:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 102:
                if (!(userLocale === 'ja')) return [3 /*break*/, 104];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ja/index.js")); })];
            case 103:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 104:
                if (!(userLocale === 'ja-Hira')) return [3 /*break*/, 106];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ja-Hira/index.js")); })];
            case 105:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 106:
                if (!(userLocale === 'ka')) return [3 /*break*/, 108];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ka/index.js")); })];
            case 107:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 108:
                if (!(userLocale === 'kk')) return [3 /*break*/, 110];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/kk/index.js")); })];
            case 109:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 110:
                if (!(userLocale === 'km')) return [3 /*break*/, 112];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/km/index.js")); })];
            case 111:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 112:
                if (!(userLocale === 'kn')) return [3 /*break*/, 114];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/kn/index.js")); })];
            case 113:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 114:
                if (!(userLocale === 'ko')) return [3 /*break*/, 116];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ko/index.js")); })];
            case 115:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 116:
                if (!(userLocale === 'lb')) return [3 /*break*/, 118];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/lb/index.js")); })];
            case 117:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 118:
                if (!(userLocale === 'lt')) return [3 /*break*/, 120];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/lt/index.js")); })];
            case 119:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 120:
                if (!(userLocale === 'lv')) return [3 /*break*/, 122];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/lv/index.js")); })];
            case 121:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 122:
                if (!(userLocale === 'mk')) return [3 /*break*/, 124];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/mk/index.js")); })];
            case 123:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 124:
                if (!(userLocale === 'mn')) return [3 /*break*/, 126];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/mn/index.js")); })];
            case 125:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 126:
                if (!(userLocale === 'ms')) return [3 /*break*/, 128];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ms/index.js")); })];
            case 127:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 128:
                if (!(userLocale === 'mt')) return [3 /*break*/, 130];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/mt/index.js")); })];
            case 129:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 130:
                if (!(userLocale === 'nb')) return [3 /*break*/, 132];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/nb/index.js")); })];
            case 131:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 132:
                if (!(userLocale === 'nl')) return [3 /*break*/, 134];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/nl/index.js")); })];
            case 133:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 134:
                if (!(userLocale === 'nl-BE')) return [3 /*break*/, 136];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/nl-BE/index.js")); })];
            case 135:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 136:
                if (!(userLocale === 'nn')) return [3 /*break*/, 138];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/nn/index.js")); })];
            case 137:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 138:
                if (!(userLocale === 'oc')) return [3 /*break*/, 140];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/oc/index.js")); })];
            case 139:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 140:
                if (!(userLocale === 'pl')) return [3 /*break*/, 142];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/pl/index.js")); })];
            case 141:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 142:
                if (!(userLocale === 'pt')) return [3 /*break*/, 144];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/pt/index.js")); })];
            case 143:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 144:
                if (!(userLocale === 'pt-BR')) return [3 /*break*/, 146];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/pt-BR/index.js")); })];
            case 145:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 146:
                if (!(userLocale === 'ro')) return [3 /*break*/, 148];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ro/index.js")); })];
            case 147:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 148:
                if (!(userLocale === 'ru')) return [3 /*break*/, 150];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ru/index.js")); })];
            case 149:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 150:
                if (!(userLocale === 'sk')) return [3 /*break*/, 152];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/sk/index.js")); })];
            case 151:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 152:
                if (!(userLocale === 'sl')) return [3 /*break*/, 154];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/sl/index.js")); })];
            case 153:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 154:
                if (!(userLocale === 'sq')) return [3 /*break*/, 156];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/sq/index.js")); })];
            case 155:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 156:
                if (!(userLocale === 'sr')) return [3 /*break*/, 158];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/sr/index.js")); })];
            case 157:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 158:
                if (!(userLocale === 'sr-Latn')) return [3 /*break*/, 160];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/sr-Latn/index.js")); })];
            case 159:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 160:
                if (!(userLocale === 'sv')) return [3 /*break*/, 162];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/sv/index.js")); })];
            case 161:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 162:
                if (!(userLocale === 'ta')) return [3 /*break*/, 164];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ta/index.js")); })];
            case 163:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 164:
                if (!(userLocale === 'te')) return [3 /*break*/, 166];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/te/index.js")); })];
            case 165:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 166:
                if (!(userLocale === 'th')) return [3 /*break*/, 168];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/th/index.js")); })];
            case 167:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 168:
                if (!(userLocale === 'tr')) return [3 /*break*/, 170];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/tr/index.js")); })];
            case 169:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 170:
                if (!(userLocale === 'ug')) return [3 /*break*/, 172];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/ug/index.js")); })];
            case 171:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 172:
                if (!(userLocale === 'uk')) return [3 /*break*/, 174];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/uk/index.js")); })];
            case 173:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 174:
                if (!(userLocale === 'uz')) return [3 /*break*/, 176];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/uz/index.js")); })];
            case 175:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 176:
                if (!(userLocale === 'uz-Cyrl')) return [3 /*break*/, 178];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/uz-Cyrl/index.js")); })];
            case 177:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 178:
                if (!(userLocale === 'vi')) return [3 /*break*/, 180];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/vi/index.js")); })];
            case 179:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 180:
                if (!(userLocale === 'zh-CN')) return [3 /*break*/, 182];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/zh-CN/index.js")); })];
            case 181:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 182:
                if (!(userLocale === 'zh-HK')) return [3 /*break*/, 184];
                return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/zh-HK/index.js")); })];
            case 183:
                localeToSet = _a.sent();
                return [3 /*break*/, 186];
            case 184: return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require("date-fns/locale/en-US/index.js")); })];
            case 185:
                localeToSet = _a.sent();
                _a.label = 186;
            case 186: 
            /*
             data-fns 3 version
             */
            /* if (userLocale === 'af') {
              localeToSet = (await import(`date-fns/locale/af`)).af;
            } else if (userLocale === 'ar') {
              localeToSet = (await import(`date-fns/locale/ar`)).ar;
            } else if (userLocale === 'ar-DZ') {
              localeToSet = (await import(`date-fns/locale/ar-DZ`)).arDZ;
            } else if (userLocale === 'ar-EG') {
              localeToSet = (await import(`date-fns/locale/ar-EG`)).arEG;
            } else if (userLocale === 'ar-MA') {
              localeToSet = (await import(`date-fns/locale/ar-MA`)).arMA;
            } else if (userLocale === 'ar-SA') {
              localeToSet = (await import(`date-fns/locale/ar-SA`)).arSA;
            } else if (userLocale === 'ar-TN') {
              localeToSet = (await import(`date-fns/locale/ar-TN`)).arTN;
            } else if (userLocale === 'az') {
              localeToSet = (await import(`date-fns/locale/az`)).az;
            } else if (userLocale === 'be') {
              localeToSet = (await import(`date-fns/locale/be`)).be;
            } else if (userLocale === 'be-tarask') {
              localeToSet = (await import(`date-fns/locale/be-tarask`)).beTarask;
            } else if (userLocale === 'bg') {
              localeToSet = (await import(`date-fns/locale/bg`)).bg;
            } else if (userLocale === 'bn') {
              localeToSet = (await import(`date-fns/locale/bn`)).bn;
            } else if (userLocale === 'bs') {
              localeToSet = (await import(`date-fns/locale/bs`)).bs;
            } else if (userLocale === 'ca') {
              localeToSet = (await import(`date-fns/locale/ca`)).ca;
            } else if (userLocale === 'cs') {
              localeToSet = (await import(`date-fns/locale/cs`)).cs;
            } else if (userLocale === 'cy') {
              localeToSet = (await import(`date-fns/locale/cy`)).cy;
            } else if (userLocale === 'da') {
              localeToSet = (await import(`date-fns/locale/da`)).da;
            } else if (userLocale === 'de') {
              localeToSet = (await import(`date-fns/locale/de`)).de;
            } else if (userLocale === 'de-AT') {
              localeToSet = (await import(`date-fns/locale/de-AT`)).deAT;
            } else if (userLocale === 'el') {
              localeToSet = (await import(`date-fns/locale/el`)).el;
            } else if (userLocale === 'en-AU') {
              localeToSet = (await import(`date-fns/locale/en-AU`)).enAU;
            } else if (userLocale === 'en-CA') {
              localeToSet = (await import(`date-fns/locale/en-CA`)).enCA;
            } else if (userLocale === 'en-GB') {
              localeToSet = (await import(`date-fns/locale/en-GB`)).enGB;
            } else if (userLocale === 'en-IE') {
              localeToSet = (await import(`date-fns/locale/en-IE`)).enIE;
            } else if (userLocale === 'en-IN') {
              localeToSet = (await import(`date-fns/locale/en-IN`)).enIN;
            } else if (userLocale === 'en-NZ') {
              localeToSet = (await import(`date-fns/locale/en-NZ`)).enNZ;
            } else if (userLocale === 'en-US') {
              localeToSet = (await import(`date-fns/locale/en-US`)).enUS;
            } else if (userLocale === 'en-ZA') {
              localeToSet = (await import(`date-fns/locale/en-ZA`)).enZA;
            } else if (userLocale === 'eo') {
              localeToSet = (await import(`date-fns/locale/eo`)).eo;
            } else if (userLocale === 'es') {
              localeToSet = (await import(`date-fns/locale/es`)).es;
            } else if (userLocale === 'et') {
              localeToSet = (await import(`date-fns/locale/et`)).et;
            } else if (userLocale === 'eu') {
              localeToSet = (await import(`date-fns/locale/eu`)).eu;
            } else if (userLocale === 'fa-IR') {
              localeToSet = (await import(`date-fns/locale/fa-IR`)).faIR;
            } else if (userLocale === 'fi') {
              localeToSet = (await import(`date-fns/locale/fi`)).fi;
            } else if (userLocale === 'fr') {
              localeToSet = (await import(`date-fns/locale/fr`)).fr;
            } else if (userLocale === 'fr-CA') {
              localeToSet = (await import(`date-fns/locale/fr-CA`)).frCA;
            } else if (userLocale === 'fr-CH') {
              localeToSet = (await import(`date-fns/locale/fr-CH`)).frCH;
            } else if (userLocale === 'fy') {
              localeToSet = (await import(`date-fns/locale/fy`)).fy;
            } else if (userLocale === 'gd') {
              localeToSet = (await import(`date-fns/locale/gd`)).gd;
            } else if (userLocale === 'gl') {
              localeToSet = (await import(`date-fns/locale/gl`)).gl;
            } else if (userLocale === 'gu') {
              localeToSet = (await import(`date-fns/locale/gu`)).gu;
            } else if (userLocale === 'he') {
              localeToSet = (await import(`date-fns/locale/he`)).he;
            } else if (userLocale === 'hi') {
              localeToSet = (await import(`date-fns/locale/hi`)).hi;
            } else if (userLocale === 'hr') {
              localeToSet = (await import(`date-fns/locale/hr`)).hr;
            } else if (userLocale === 'ht') {
              localeToSet = (await import(`date-fns/locale/ht`)).ht;
            } else if (userLocale === 'hu') {
              localeToSet = (await import(`date-fns/locale/hu`)).hu;
            } else if (userLocale === 'hy') {
              localeToSet = (await import(`date-fns/locale/hy`)).hy;
            } else if (userLocale === 'id') {
              localeToSet = (await import(`date-fns/locale/id`)).id;
            } else if (userLocale === 'is') {
              localeToSet = (await import(`date-fns/locale/is`)).is;
            } else if (userLocale === 'it') {
              localeToSet = (await import(`date-fns/locale/it`)).it;
            } else if (userLocale === 'it-CH') {
              localeToSet = (await import(`date-fns/locale/it-CH`)).itCH;
            } else if (userLocale === 'ja') {
              localeToSet = (await import(`date-fns/locale/ja`)).ja;
            } else if (userLocale === 'ja-Hira') {
              localeToSet = (await import(`date-fns/locale/ja-Hira`)).jaHira;
            } else if (userLocale === 'ka') {
              localeToSet = (await import(`date-fns/locale/ka`)).ka;
            } else if (userLocale === 'kk') {
              localeToSet = (await import(`date-fns/locale/kk`)).kk;
            } else if (userLocale === 'km') {
              localeToSet = (await import(`date-fns/locale/km`)).km;
            } else if (userLocale === 'kn') {
              localeToSet = (await import(`date-fns/locale/kn`)).kn;
            } else if (userLocale === 'ko') {
              localeToSet = (await import(`date-fns/locale/ko`)).ko;
            } else if (userLocale === 'lb') {
              localeToSet = (await import(`date-fns/locale/lb`)).lb;
            } else if (userLocale === 'lt') {
              localeToSet = (await import(`date-fns/locale/lt`)).lt;
            } else if (userLocale === 'lv') {
              localeToSet = (await import(`date-fns/locale/lv`)).lv;
            } else if (userLocale === 'mk') {
              localeToSet = (await import(`date-fns/locale/mk`)).mk;
            } else if (userLocale === 'mn') {
              localeToSet = (await import(`date-fns/locale/mn`)).mn;
            } else if (userLocale === 'ms') {
              localeToSet = (await import(`date-fns/locale/ms`)).ms;
            } else if (userLocale === 'mt') {
              localeToSet = (await import(`date-fns/locale/mt`)).mt;
            } else if (userLocale === 'nb') {
              localeToSet = (await import(`date-fns/locale/nb`)).nb;
            } else if (userLocale === 'nl') {
              localeToSet = (await import(`date-fns/locale/nl`)).nl;
            } else if (userLocale === 'nl-BE') {
              localeToSet = (await import(`date-fns/locale/nl-BE`)).nlBE;
            } else if (userLocale === 'nn') {
              localeToSet = (await import(`date-fns/locale/nn`)).nn;
            } else if (userLocale === 'oc') {
              localeToSet = (await import(`date-fns/locale/oc`)).oc;
            } else if (userLocale === 'pl') {
              localeToSet = (await import(`date-fns/locale/pl`)).pl;
            } else if (userLocale === 'pt') {
              localeToSet = (await import(`date-fns/locale/pt`)).pt;
            } else if (userLocale === 'pt-BR') {
              localeToSet = (await import(`date-fns/locale/pt-BR`)).ptBR;
            } else if (userLocale === 'ro') {
              localeToSet = (await import(`date-fns/locale/ro`)).ro;
            } else if (userLocale === 'ru') {
              localeToSet = (await import(`date-fns/locale/ru`)).ru;
            } else if (userLocale === 'sk') {
              localeToSet = (await import(`date-fns/locale/sk`)).sk;
            } else if (userLocale === 'sl') {
              localeToSet = (await import(`date-fns/locale/sl`)).sl;
            } else if (userLocale === 'sq') {
              localeToSet = (await import(`date-fns/locale/sq`)).sq;
            } else if (userLocale === 'sr') {
              localeToSet = (await import(`date-fns/locale/sr`)).sr;
            } else if (userLocale === 'sr-Latn') {
              localeToSet = (await import(`date-fns/locale/sr-Latn`)).srLatn;
            } else if (userLocale === 'sv') {
              localeToSet = (await import(`date-fns/locale/sv`)).sv;
            } else if (userLocale === 'ta') {
              localeToSet = (await import(`date-fns/locale/ta`)).ta;
            } else if (userLocale === 'te') {
              localeToSet = (await import(`date-fns/locale/te`)).te;
            } else if (userLocale === 'th') {
              localeToSet = (await import(`date-fns/locale/th`)).th;
            } else if (userLocale === 'tr') {
              localeToSet = (await import(`date-fns/locale/tr`)).tr;
            } else if (userLocale === 'ug') {
              localeToSet = (await import(`date-fns/locale/ug`)).ug;
            } else if (userLocale === 'uk') {
              localeToSet = (await import(`date-fns/locale/uk`)).uk;
            } else if (userLocale === 'uz') {
              localeToSet = (await import(`date-fns/locale/uz`)).uz;
            } else if (userLocale === 'uz-Cyrl') {
              localeToSet = (await import(`date-fns/locale/uz-Cyrl`)).uzCyrl;
            } else if (userLocale === 'vi') {
              localeToSet = (await import(`date-fns/locale/vi`)).vi;
            } else if (userLocale === 'zh-CN') {
              localeToSet = (await import(`date-fns/locale/zh-CN`)).zhCN;
            } else if (userLocale === 'zh-HK') {
              localeToSet = (await import(`date-fns/locale/zh-HK`)).zhHK;
            } else {
              localeToSet = (await import(`date-fns/locale/en-US`)).enUS;
            } */
            return [2 /*return*/, localeToSet];
        }
    });
}); };
exports.importLocale = importLocale;
