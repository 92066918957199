/*!
 * Required Param:job, categories, types, duplicates, feedbackList, onSelectDecision,
 */

import _ from 'lodash';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '@store';
import React from 'react';
import ItemDetailPage from '@features/ItemDetailPage';

(function () {
  'use strict';

  function Controller($timeout, $state, $element, $stateParams, $scope,
    getModerationNotesQueryFields, CurrentUser, EvaLogger, UserPermissionManager) {
    'ngInject';
    const GALLERY_SIZE_MEDIUM = { width: 270, height: 178 };
    const GALLERY_SIZE_LARGE = { width: 540, height: 360 };
    const vm = this;
    let root;

    vm.$onInit = () => {
      const images = _.get(vm, 'job.ad.current.content.images');
      const videos = _.get(vm, 'job.ad.videosInfo.content');
      vm.domain = $stateParams.domain;
      vm.isQueuePage = $stateParams.queueId ? true : false;
      vm.currentUserEmail = CurrentUser.email;
      vm.isUsingImageGalleryMedium = vm.template && vm.template.image_gallery_id === 1;
      vm.isUsingImageGalleryLarge = vm.template && vm.template.image_gallery_id === 2;

      const containsMedia = () => {
        return (images && images.length > 0) || (videos && videos.length > 0);
      };
      vm.containsMedia = containsMedia();

      renderJobDetail();
    };

    const renderJobDetail = () => {
      $timeout(() => {
        if (!root) {
          root = createRoot(document.querySelector('#job-detail-' + vm.job.ad.id));
        }
        root.render(
          <Provider store={store}>
            <ItemDetailPage
              job={vm.job}
              domain={$stateParams.domain}
              updateItemCurrent={vm.updateItemCurrent}
              updateItemCustomerSpecific={vm.updateItemCustomerSpecific}
              updateVideoInfo={vm.updateVideoInfo}
              angularJsDependencies={{ EvaLogger, $state, CurrentUser, UserPermissionManager }}
              isNoteExpanded={vm.isNotesExpanded}
              isUsingImageGallery1={vm.isUsingImageGallery1}
              isUsingImageGallery2={vm.isUsingImageGallery2}
              editPermissions={vm.editPermissions}
              index={vm.index}
              template={vm.template}
              isQueuePage={vm.isQueuePage}
              moderationNote={{
                onNewNoteCreated: vm.onNewNoteCreated,
                removeNoteFromList: (noteId) => {
                  _.remove(vm.job.moderationNotes.notes, { id: noteId });
                  renderJobDetail();
                },
                reloadNotes: vm.failedToLoadModerationNotes,
              }}

            />
          </Provider>,
        );
      });
    };

    vm.updateItemCurrent = function(current) {
      vm.job.ad.current = current;
      renderJobDetail();
    };
    vm.updateItemCustomerSpecific = function(cs) {
      vm.job.ad.customerSpecific = cs;
      renderJobDetail();
    };

    vm.updateVideoInfo = function(videos) {
      vm.job.ad.videosInfo.content = videos;
      renderJobDetail();
    };


    vm.focusSelf = function () {
      if (vm.focusJob) vm.focusJob();
    };


    $timeout(() => {
      // XXX: cannot achieve this by pure css ".media-gallery:empty", because there is empty space inside ".media-gallery"
      if ($element.find('.media-gallery > *').length === 0 && $element.find('eva-job-content-item > *').length === 0) {
        $element.find('.eva-job-content-image-container + hr').hide();
      }
    });

    /**
   *
   * @param selectedIds {Array} an array of objects [{id: 1}, {id: 2}]
   */
    vm.updateFeedback = (selectedFeedback) => vm.job.selectedFeedback = selectedFeedback.map(({ id }) => ({ id }));

    vm.updateSelectedImageIndex = (index) => {
      vm.updateSelectedJobImageIndex({ index: index });
    };

    vm.noticeTriggered = (id, value) => {
      const attr = `showBoundingBox.${id}`;
      if (value === true || value === false) {
        _.set(vm.job, attr, value);
        renderJobDetail();
      }
    };


    $scope.$watchGroup(['$ctrl.job.isEditDisabled', '$ctrl.isNotesExpanded', '$ctrl.job.duplicatesCount'], () => {
      renderJobDetail();
    });



    vm.setModerationNotesOpenStatus = (value) => {
      vm.setIsNotesExpanded({ value });
      if (value) {
        vm.mainImageContainerSize = vm.isUsingImageGalleryMedium ? GALLERY_SIZE_MEDIUM : GALLERY_SIZE_LARGE;
      } else {
        vm.mainImageContainerSize = null;
        vm.thumbnailContainerSize = null;
      }
    };

    vm.onNewNoteCreated = () => {
      vm.onNewModerationNoteCreated({ query: getModerationNotesQueryFields(vm.job.ad) });
      renderJobDetail();
    };


    $scope.$watch('$ctrl.job.moderationNotes.notes', (newValue) => {
      if (Array.isArray(newValue) && newValue.length > 0) {
        vm.setModerationNotesOpenStatus(true);
      }
      renderJobDetail();
    });

  }



  angular
    .module('eva2-angular')
    .component('evaJob', {
      bindings: {
        job: '<',
        categories: '<',
        availableQueues: '<',
        types: '<',
        feedbackList: '<',
        hideReviewBar: '<',
        disableReviewBar: '<',
        onSelectDecision: '&',
        focusJob: '&',
        template: '<',
        editPermissions: '<',
        preSelectedImageIndex: '<',
        updateSelectedJobImageIndex: '&',
        dictionaries: '<',
        showModerationNotesIndicator: '<',
        onNewModerationNoteCreated: '&',
        failedToLoadModerationNotes: '&',
        setIsNotesExpanded: '&',
        isNotesExpanded: '<',
        isTheNewestVersion: '<',
        index: '<'
      },

      template: require('./job.html'),
      controller: Controller
    });
})();
