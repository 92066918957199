import { handleAuthRedirection } from '@/react-app/common/utils/handleAuth0redirect';
import _ from 'lodash';


angular.module('eva2-angular').run(function ($rootScope, $location, $state, CurrentUser,
  RequestTracker, PageTitle, Notifier, Analytics, TransitRestriction,
  $modalStack, ApiService, $stateParams, getDeleteAllContentStatus,
  $templateCache, userSetting, $ngRedux, tFilter) {
  'use strict';

  // $templateCache.put('app/partial/table/pagination.html', require('../app/partial/table/pagination.html'));

  Analytics.initialize();

  $rootScope.$on('$stateChangeError',
    function (event, toState, toParams, fromState, fromParams, error) {
      let msg = tFilter('OTHER_NOTIFIER.ERROR.CANNOT_NAVIGATE');
      if (error && error.status === -1) {
        msg += tFilter('OTHER_NOTIFIER.ERROR.OFFLINE');
      }
      Notifier.display(msg, {type: 'failure', ttl: 6000}, error);
    });

  const removeListener = $rootScope.$on('$stateChangeStart', populateUserData);

  function populateUserData(event, to, toParams) {
    // When application is being initialized, first state transition is
    // postponed and application is trying to get user data using existing
    // session data, stored in local storage

    // $stateChangeStart' is executed on page change (including reload)
    // To make sure that populateUserData is executed only one (on reload) => we remove the listener
    removeListener();
    event.preventDefault();

    const callback = () => {
      enableTransitRestriction();
      RequestTracker.activate();      
      $state.go(to.name, toParams);
    };
    handleAuthRedirection(CurrentUser,$state, callback);
  }

  function enableTransitRestriction() {
    $rootScope.$on('$stateChangeStart', TransitRestriction.$stateChangeStartListener);
  }

  // Add listener that will auto-collapse any expanded menu in navigation bar upon change in router's current state
  // such as user clicking an option in the nav menu to navigate to new section
  $rootScope.$on('$stateChangeStart', function () {
    RequestTracker.clearAll();
    angular.element('.eva-nav-settings > ul > li').removeClass('hovered');
    //dismiss all modals when route changes
    $modalStack.dismissAll();
    $ngRedux.dispatch({type: 'ROUTE_CHANGE'});
  });

  $rootScope.$on('$stateChangeSuccess', (event, state, params) => {
    $rootScope.hasSecondaryMenu = (state && state.data &&
      state.data.nav && state.data.nav.secondary &&
      state.data.nav.secondary.length > 0);

    PageTitle.setTitle(state.data && tFilter(state.data.title));
    Analytics.pageview($location.path(), PageTitle.current, params);

    if ($rootScope.showDeleteAllContentNotice && params.domain) {
      getDeleteAllContentStatus(params.domain);
    }
    if (_.includes($state.current.name, 'app.assignment.') && params.domain) {
      userSetting.saveUserSetting('lastUsedDomain', params.domain);
    }
  });

  $rootScope.dateFormat = 'dd MMM yyyy HH:mm';
  $rootScope.displayAuth0Notice = false;
  window.addEventListener('beforeunload', function () {
    // When user closes tab, if he is currently reviewing ads, unlock those ads.
    const isInReviewingQueue = $state.current.name === 'app.assignment.manual.queue';
    if (isInReviewingQueue) {
      ApiService.unlockAds($stateParams.domain);
      $rootScope.$digest();
    }
  });
});

