"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getComparator = exports.descendingComparator = void 0;
var descendingComparator = function (a, b, orderBy) {
    // Check if orderBy is null or undefined
    if (a[orderBy] == null || b[orderBy] == null) {
        return 0;
    }
    // Check if the value type is Date and compare it
    if (a[orderBy] instanceof Date && b[orderBy] instanceof Date) {
        return b[orderBy].getTime() - a[orderBy].getTime();
    }
    // Check if the value type is string and compare it
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
        return b[orderBy].localeCompare(a[orderBy]);
    }
    // Check if the value type is number or bigint and compare it
    if ((typeof a[orderBy] === 'number' || typeof a[orderBy] === 'bigint') &&
        (typeof b[orderBy] === 'number' || typeof b[orderBy] === 'bigint')) {
        return Number(b[orderBy]) - Number(a[orderBy]);
    }
    // Check if the value type is boolean and compare it
    if (typeof a[orderBy] === 'boolean' && typeof b[orderBy] === 'boolean') {
        // eslint-disable-next-line no-nested-ternary
        return b[orderBy] === a[orderBy] ? 0 : a[orderBy] ? -1 : 1;
    }
    // If none of the above conditions are met, return 0
    return 0;
};
exports.descendingComparator = descendingComparator;
var getComparator = function (order, orderBy) {
    return order === 'desc'
        ? function (a, b) { return (0, exports.descendingComparator)(a, b, orderBy); }
        : function (a, b) { return -(0, exports.descendingComparator)(a, b, orderBy); };
};
exports.getComparator = getComparator;
