import { itemLogViewDefaultStartDate } from '@features/ItemLogPage/util/constants';
import _ from 'lodash';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .directive('evaNavbarSecondary', navbarSecondary);

  navbarSecondary.$inject = [];

  function navbarSecondary() {
    return {
      restrict: 'E',
      replace: true,
      controller: Controller,
      scope: {
        items: '<'
      },
      template: require('./navbar-secondary.html')
    };
  }

  function Controller($scope, UserPermissionManager,$ngRedux, $stateParams) {
    'ngInject';
    $scope.itemLogViewDefaultStartDate=itemLogViewDefaultStartDate.getTime();
    $scope.$watch('items', ()=> {
      _.forEach($scope.items, (item) => {
        item.disabled = !UserPermissionManager.hasPermissionToEnterRoute(item.state, $stateParams.domain);
      });
    });
  }
})();
