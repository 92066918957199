import _ from 'lodash';
(function() {
  'use strict';
  angular.module('eva2-angular').service('goToDefault', function($state, CurrentUser,
    userSetting, ApiService) {
    const disallowHistoryBack = {location: 'replace'};

    const getAccessibleTeams = async ()=> {
      let teams = CurrentUser.getTeamsUserHasAccessTo();
      if (teams) {
        return teams;
      } 
      try {
        const data = await ApiService.getUser();
        CurrentUser.logIn(data);
        return CurrentUser.getTeamsUserHasAccessTo();
      } catch {
        return null;
      }
    };

    async function goToDefault(param = {}) {
      const accessibleTeams = await getAccessibleTeams();
      const lastUsedDomain = userSetting.getUserSetting('lastUsedDomain');
      const IsLastUsedDomainAccessible = _.some(accessibleTeams, (team)=> team.domain === lastUsedDomain);
      const domain = IsLastUsedDomainAccessible ? lastUsedDomain : _.get(accessibleTeams, '[0].domain');
      if (!domain) {
        // $state.go('app.forbidden');
        $state.go('app.createTeam', {}, disallowHistoryBack);
      } else {
        $state.go('app.assignment.overview.statistics',
          angular.extend({}, param, {domain}),
          disallowHistoryBack);
      }
    }

    return goToDefault;
  });
})();
