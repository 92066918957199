import Dictionaries from '@/react-app/features/Dictionaries';
import store from '@store';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('AdminDictionariesCtrl', AdminDictionariesCtrl);


  function AdminDictionariesCtrl($stateParams,
    $timeout, $state, UserPermissionManager, CurrentUser, $scope) {
    const vm = this;
    const root = createRoot(document.querySelector('#dictionariesPage'));
    vm.domain = $stateParams.domain;

    vm.$onInit = function() {
      renderDictionaries();
    };

    $scope.$on('$destroy', ()=> {
      root.unmount();
    });
    

    const renderDictionaries = () => {
      $timeout(() => {
        root.render(
          <Provider store={store}>
            <Dictionaries  domain={$stateParams.domain}
              angularJsDependencies={
                {
                  href: $state.href,
                  userPermissionManager: UserPermissionManager,
                  go: $state.go,
                }
              }
              currentUser={CurrentUser}
            />
          </Provider>
        );
      });
    };
  }
})();
