'use strict';
import { handleAuthLogout } from '@/react-app/common/utils/handleAuth0redirect';
import _ from 'lodash';
angular.module('eva2-angular').service('ApiService', ($http, tFilter, ajax, EXTERNAL_API_URL, groupByFilter) => {
  const ApiService = {
    getOverallStatistics: (domain, from, to, tz) => ajax.get(`/sites/${domain}/stats/overall`, {params: {from, to, tz}}),
    getOverview: (domain, from, to, tz) => ajax.get(`/sites/${domain}/stats/2/overall`, {params: {from, to, tz}}),
    getOverviewWithTimeZone: (domain, from, to, tz) => ajax.get(`/sites/${domain}/stats/2/overall-timezone`, {params: {from, to, tz}}),
    getDomainData: (domain) => ajax.get(`/sites/${domain}`),
    postJobsFromCSV: function(data, apiKey) {
      return $http({
        url: EXTERNAL_API_URL,
        method: 'POST',
        headers: {
          'x-api-key': apiKey
        },
        data: data,
        withCredentials: false
      }).then((response) => response.data);
    },
    //manual
    getManualSetting: function(domain) {
      return ajax.get(`/sites/${domain}/settings/manual`);
    },
    updateManualSetting: function(domain, data) {
      return ajax.put(`/sites/${domain}/settings/manual`, data);
    },
    login: (username, password) => ajax.post('/login', {username, password}),
    logout: ()=> handleAuthLogout(),
    getUser: () => ajax.get('/user'),
    updateWebHookUrl: (domain, data) => ajax.put(`/sites/${domain}/endpoint-url`, data),
    getSites: () => ajax.get('/sites'),
    getAd: (domain, id) => ajax.get(`/ads/${domain}/${id}`),
    changePassword: (data) => ajax.post('/user/change_password', data),
    createInvite: (domain, data) => ajax.post(`/sites/${domain}/invites`, data),
    getInvites: (domain) => ajax.get(`/sites/${domain}/invites`),
    getMembers: (domain) => ajax.get(`/sites/${domain}/members`),
    getQueueStats: (domain) => ajax.get(`/sites/${domain}/stats/queues`).then((queues)=> {
      return queues.map((item) => {
        if (item.count === '>10000') {
          item.count = '10000+';
        }
        return item;
      });
    }),
    getRoles: ()=> ajax.get('/roles'),
    updateRoleForTeamMember: (domain, data) => ajax.post(`/sites/${domain}/user/roles`, data),
    updateRoleForPendingInvite: (domain, data) => ajax.put(`/sites/${domain}/invites`, data),
    unlockAds: (domain) => ajax.del(`/sites/${domain}/ads/process`),
    getUserLocation: () => ajax.get('/location'),
    getBlangVariables: (domain) => ajax.get(`/sites/${domain}/blangVariables`),
    deleteAllContent: (domain, timeZone) => ajax.post(`/sites/${domain}/site_content_deletion_jobs`, {timeZone}),
    getContentDeletionStatus: (domain) => ajax.get(`/sites/${domain}/site_content_deletion_jobs`),
    cancelContentDeletion: (domain, jobId) => ajax.del(`/sites/${domain}/site_content_deletion_jobs/${jobId}`),
    getDataStoringOptions: ()=> ajax.get('/retentions'),
    getDataStoringOptionByDomain: (domain)=> ajax.get(`/sites/${domain}/retention`),
    updateDataStoringOptionByDomain: (domain, data)=> ajax.put(`/sites/${domain}/retention`, data),
    removeDataStoringOptionByDomain: (domain)=> ajax.del(`/sites/${domain}/retention`),
    deleteUserData: (domain, userId)=> ajax.post(`/sites/${domain}/user_content_deletion_jobs`, {deletion_user_id: userId}),
    getImplioStatus: ()=> {
      const url = 'https://implio.statuspage.io/api/v2/status.json';
      return $http({
        url: url,
        method: 'GET',
        withCredentials: false
      }).then((response) => response.data);
    },
    addModerationQueue: (domain, queueName) => ajax.post(`/sites/${domain}/queues`, {queueName}),
    getModerationQueues: (domain) => ajax.get(`/sites/${domain}/queues`),
    editModerationQueue: (domain, queueId, queueName) => ajax.put(`/sites/${domain}/queues/${queueId}`, {queueName}),
    deleteModerationQueue: (domain, queueId) => ajax.del(`/sites/${domain}/queues/${queueId}`),
    getFiltersUsedByQueue: (domain, queueId) => ajax.get(`/sites/${domain}/searchFiltersByQueueId?queueId=${queueId}`),
    getSiteDetails: (domain)=> ajax.get(`/sites/${domain}`),
    updateSiteDetails: (domain, data)=> ajax.put(`/sites/${domain}`, data),
    getRefusalReasons: (domain)=> ajax.get(`/sites/${domain}/decisions`).then((data)=> {
      const result = groupByFilter(_.get(data, 'results'), 'categoryName');
      if (result && result.length === 1) {
        result[0].key = tFilter('ITEM.DEFAULT_REFUSE_BUTTON_LABEL');
      }
      return result;
    }),
    getUserPreviousItemsCount: (domain, userId, status) => ajax.get(`/sites/${domain}/ads/count`,
      {params: {domain, query: `document.user.id: "${userId}" AND metadata.result: ${status}`}}),
    addWordToList: (domain, listName, wordToAdd)=> ajax.patch(`/sites/${domain}/filters/dictionaries/${listName}/items`, {item: wordToAdd}),
    validateBlangRule: (domain, rule)=> ajax.post(`/sites/${domain}/filters/rule/validate`, {rule}),
    searchDocuments: (domain, params, timeout) => ajax.get(`/sites/${domain}/ads/queryDs`, {params, timeout}),
    getModerationNotes: (domain, query)=> ajax.get(`/sites/${domain}/moderationNotes`, {params: query}),
    createModerationNote: (domain, data)=> ajax.post(`/sites/${domain}/moderationNotes`, data),
    deleteModerationNote: (domain, moderationNoteId) => ajax.del(`/sites/${domain}/moderationNotes/${moderationNoteId}`),
    getDocumentsCount: (domain, query, timeout)=> ajax.get(`/sites/${domain}/ads/count`,
      {params: {domain, query}, timeout}),
    getTeamCreationStatus: (domain) =>  ajax.get(`/sites/${domain}/creationStatus`),
    getTrustedDomains: (domain) => ajax.get(`/sites/${domain}/trustedDomains`),
  };
  return ApiService;
});
