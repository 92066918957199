"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetFeedbacksQuery = exports.feedbacksApi = void 0;
var baseApi_1 = require("./baseApi");
// Define a service using a base URL and expected endpoints
exports.feedbacksApi = baseApi_1.baseApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getFeedbacks: builder.query({
            query: function (domain) { return ({
                url: "/sites/".concat(domain, "/feedbacks"),
                method: 'GET',
            }); },
        }),
    }); },
});
exports.useGetFeedbacksQuery = exports.feedbacksApi.useGetFeedbacksQuery;
