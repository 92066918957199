"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetTrustedDomainQuery = exports.trustedDomainApi = void 0;
var baseApi_1 = require("@common/services/baseApi");
exports.trustedDomainApi = baseApi_1.baseApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getTrustedDomain: builder.query({
            query: function (domain) { return ({
                url: "/sites/".concat(domain, "/trustedDomains"),
                method: 'GET',
            }); },
            transformResponse: function (rawResult) {
                return rawResult.map(function (trustedDomain) { return trustedDomain.trustedDomain; });
            },
        }),
    }); },
});
exports.useGetTrustedDomainQuery = exports.trustedDomainApi.useGetTrustedDomainQuery;
