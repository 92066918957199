"use strict";
/**
 * Get Refusal reason if the decison has been taken by implio
 * if it's an external decision return an empty string
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRefusalReasonFromJobHistory = exports.getRefusalReasons = void 0;
var jobItemHistory_1 = require("@/react-app/common/utils/jobItemHistory");
// TO DO remove the duplication of this function inside getDecisionSummary
function getRefusalReasons(job) {
    var _a, _b, _c, _d, _e;
    var showClientResult = ((_c = (_b = (_a = job === null || job === void 0 ? void 0 : job.ad) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.actor) === null || _c === void 0 ? void 0 : _c.domain) === 'client';
    if (showClientResult || !job.ad) {
        return '';
    }
    // TO DO verify with the backend team if ad.current.actor is always defined  or that the ad cannot contain it if the item is in the processing state
    // Because the unit test 'when ad is processing' and 'when ad is reviewable' are providing a job without ad.current.actor
    var status = (0, jobItemHistory_1.getFullJobStatusFilter)(job.ad.current.meta.status, (_d = job.ad.current.actor) === null || _d === void 0 ? void 0 : _d.domain);
    if (status === 'manual-refused' && Array.isArray(job.ad.current.meta.decisions)) {
        return (_e = job.ad.current.meta.decisions[0]) === null || _e === void 0 ? void 0 : _e.name;
    }
    //  get all decisionName from decisions separated by comma
    if (status === 'auto-refused' && Array.isArray(job.ad.current.meta.decisions)) {
        return job.ad.current.meta.decisions.map(function (result) { return result.name; }).join(', ');
    }
    return '';
}
exports.getRefusalReasons = getRefusalReasons;
function getRefusalReasonFromJobHistory(jobHistory) {
    var _a, _b;
    // TO DO verify with the backend team if ad.current.actor is always defined  or that the ad cannot contain it if the item is in the processing state
    // Because the unit test 'when ad is processing' and 'when ad is reviewable' are providing a job without ad.current.actor
    var status = (0, jobItemHistory_1.getFullJobStatusFilter)(jobHistory.meta.status, (_a = jobHistory.actor) === null || _a === void 0 ? void 0 : _a.domain);
    // Inside the jobHistory The api is returning the refusal reason only for Manual refused
    // For the moment we can get the refusal reason for auto refused from JobHistory.processingResults
    if (status === 'manual-refused' && Array.isArray(jobHistory.meta.decisions)) {
        return (_b = jobHistory.meta.decisions[0]) === null || _b === void 0 ? void 0 : _b.name;
    }
    //  get all decisionName from processingResults separated by comma
    //  TO DO create a ticket to get the refusal reason for auto refused from JobHistory.meta.decisions such as manual refused
    if (status === 'auto-refused' && Array.isArray(jobHistory.processingResults)) {
        return jobHistory.processingResults
            .filter(function (processResult) { return processResult.result === 'REFUSE'; })
            .map(function (result) { return result.decisionName; })
            .join(', ');
    }
    return '';
}
exports.getRefusalReasonFromJobHistory = getRefusalReasonFromJobHistory;
