"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHtmlizer = void 0;
var react_1 = require("react");
var clickOutsideHook_1 = require("@common/hooks/clickOutsideHook");
var htmlizerWorker_1 = require("@components/Htmlizer/htmlizerWorker");
var useHtmlizer = function (containerRef, onClickOutside, mode, tempValue, value, fieldsToHighlight, scope, job, domain, angularJsDependencies) {
    var _a = __read((0, react_1.useState)(), 2), htmlParts = _a[0], setHtmlParts = _a[1];
    (0, clickOutsideHook_1.useClickOutside)(containerRef, function () {
        if (mode === 'EDIT') {
            onClickOutside();
            updateHtmlParts(tempValue || '');
        }
    }, [tempValue, mode]);
    var handleKeyDown = function (event) {
        if (event.key === 'Enter' && event.shiftKey) {
            onClickOutside();
            updateHtmlParts(tempValue || '');
            event.stopPropagation();
            event.preventDefault();
        }
    };
    (0, react_1.useEffect)(function () {
        updateHtmlParts(value || '');
    }, []);
    var updateHtmlParts = function (newVal) {
        var _a, _b;
        // Set the htmlParts to an empty array to avoid flickering
        setHtmlParts([
            {
                value: newVal,
                type: 'text',
                highlighted: [],
            },
        ]);
        (0, htmlizerWorker_1.htmlizerService)({
            text: newVal,
            rules: ((_a = job === null || job === void 0 ? void 0 : job.ad) === null || _a === void 0 ? void 0 : _a.current.processingResults) || [],
            adFieldsToHighlight: fieldsToHighlight,
        }, angularJsDependencies.EvaLogger, { domain: domain, itemId: ((_b = job === null || job === void 0 ? void 0 : job.ad) === null || _b === void 0 ? void 0 : _b.remoteId.id) || '', type: scope }).then(function (result) {
            setHtmlParts(result);
        });
    };
    return { htmlParts: htmlParts, updateHtmlParts: updateHtmlParts, handleKeyDown: handleKeyDown };
};
exports.useHtmlizer = useHtmlizer;
