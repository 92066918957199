"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetTypesQuery = exports.useGetCategoriesQuery = exports.categoriesApi = void 0;
var baseApi_1 = require("@common/services/baseApi");
// // Define a service using a base URL and expected endpoints
exports.categoriesApi = baseApi_1.baseApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getCategories: builder.query({
            query: function (domain) { return ({ url: "/sites/".concat(domain, "/categories"), method: 'GET' }); },
        }),
        getTypes: builder.query({
            query: function (domain) { return ({ url: "/sites/".concat(domain, "/types"), method: 'GET' }); },
        }),
    }); },
});
exports.useGetCategoriesQuery = exports.categoriesApi.useGetCategoriesQuery, exports.useGetTypesQuery = exports.categoriesApi.useGetTypesQuery;
