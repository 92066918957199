"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRulesAndHistoryModal = void 0;
var react_1 = __importStar(require("react"));
var dataStoring_1 = require("@common/services/dataStoring");
var fromDateUtil_1 = require("@features/ItemLogPage/util/fromDateUtil");
var index_1 = require("@features/ItemCommon/components/RulesAndHistoryModal/index");
var useRulesAndHistoryModal = function (job) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var remoteIdDomain = ((_a = job === null || job === void 0 ? void 0 : job.ad) === null || _a === void 0 ? void 0 : _a.remoteId.domain) || '';
    var _j = __read((0, react_1.useState)(null), 2), selectedJob = _j[0], setSelectedJob = _j[1];
    var _k = __read((0, react_1.useState)(false), 2), isOpen = _k[0], setIsOpen = _k[1];
    var _l = __read(react_1.default.useState(index_1.TAB_HISTORY), 2), selectedTab = _l[0], setSelectedTab = _l[1];
    var storingOpt = (0, dataStoring_1.useGetDataStoringOptionQuery)(remoteIdDomain).data;
    var minDate = (0, react_1.useMemo)(function () {
        if (storingOpt) {
            return (0, fromDateUtil_1.minDateFromPeriod)(storingOpt.period);
        }
        return null;
    }, [storingOpt]);
    var historyLength = ((_c = (_b = job === null || job === void 0 ? void 0 : job.ad) === null || _b === void 0 ? void 0 : _b.history) === null || _c === void 0 ? void 0 : _c.length) || 0;
    var numberOfDecisions = ((_e = (_d = job === null || job === void 0 ? void 0 : job.ad) === null || _d === void 0 ? void 0 : _d.history) === null || _e === void 0 ? void 0 : _e.filter(function (h) { return h.meta.status === 'refused' || h.meta.status === 'approved'; }).length) || 0;
    var currentStatus = (_h = (_g = (_f = job === null || job === void 0 ? void 0 : job.ad) === null || _f === void 0 ? void 0 : _f.current) === null || _g === void 0 ? void 0 : _g.meta) === null || _h === void 0 ? void 0 : _h.status;
    return {
        selectedJob: selectedJob,
        setSelectedJob: setSelectedJob,
        isOpen: isOpen,
        setIsOpen: setIsOpen,
        selectedTab: selectedTab,
        setSelectedTab: setSelectedTab,
        minDate: minDate,
        historyLength: historyLength,
        numberOfDecisions: numberOfDecisions,
        currentStatus: currentStatus,
    };
};
exports.useRulesAndHistoryModal = useRulesAndHistoryModal;
