import _ from 'lodash';
import {getIntervalFromNow} from '@utils/date';
import { getRefusalReasons } from '@/react-app/features/ItemCommon/utils/getRefusalReasons';

(function() {
  'use strict';
  angular.module('eva2-angular').service('getDecisionSummary', function(tFilter, durationFilter, hyphenizeFilter,
    getCurrentQueueNameAndId) {
    return function(job) {
      if (!job || !job.ad) {
        return null;
      }
      function getClientModerationResult(job) {
        if(job && job.ad && job.ad.current && job.ad.current.meta && job.ad.current.meta.clientModerationResult) {
          const clientModerationResult = job.ad.current.meta.clientModerationResult;
          return clientModerationResult[clientModerationResult.length - 1];
        }
      }

      const format = 'DD/MM/YYYY  HH:mm:ss';
      const showClientResult = _.get(job, 'ad.current.actor.domain') === 'client';
      const clientModerationResult = getClientModerationResult(job);
      const status = getStatus(job);
      const hasDecision = ['approved', 'refused', 'no decision'].indexOf(status) !== -1;
      const timestamp = getTimeStamp(job);
      const timeEntered = moment(_.get(job, 'ad.current.meta.timeEntered'));
      const timeEnteredFormatted = timeEntered.format(format);
      const timeSinceEntered = getTimeSinceEntered();
      const utcOffset = moment().utcOffset() / 60;
      const timeZone = 'GMT' + (utcOffset > 0 ? `+${utcOffset}` : utcOffset);
      const timestampFormatted = timestamp.format(format);
      const icons = {
        approved: 'check',
        refused: 'cross',
        'no decision': 'dash',
        reviewable: 'send-to',
        deferred: 'send-to',
        processing: 'cog'
      };
      const {timeNum, timeUnit} = getDecisionFromNow(timestamp);

      function getDurationText(timeArray) {
        // convert time array to a string, e.g "*d *h *m *s *ms"
        const unitArray = ['d', 'h', 'm', 's', 'ms'];
        // first non zero index is the first index where time is > 0
        const firstNonZeroIndex = timeArray.findIndex((time) => time > 0);
        // second non zero index is the first index where time is > 0 and index is not the first non zero index
        const secondNonZeroIndex = timeArray.findIndex((time, index) => time > 0 && index !== firstNonZeroIndex);
        const isFirstAndSecondsIndexConsecutive = secondNonZeroIndex - firstNonZeroIndex === 1;

        return (
          timeArray.reduce((prevValue, currentValue, currentIndex) => {
            if (currentIndex === firstNonZeroIndex) {
              return `${currentValue}${unitArray[currentIndex]}`;
            }

            if (currentIndex === secondNonZeroIndex && isFirstAndSecondsIndexConsecutive) {
              return `${prevValue} ${currentValue}${unitArray[currentIndex]}`;
            }
            return prevValue;
          }, '') || '0s'
        );
      }

      function getStatus(job) {
        return showClientResult ? _.get(clientModerationResult, 'outcome') : _.get(job, 'ad.current.meta.status');
      }


      function getTimeToDecision(job) {
        if (job.isPostReviewed || showClientResult) {
          return '';
        }
        const timeDecisionMade = moment(_.get(job, 'ad.current.timestamp'));

        return tFilter('ITEM.DECISION_SUMMARY.TIME_TO_DECISION',
          {TIME: durationFilter(timeDecisionMade.diff(timeEntered), false, getDurationText)}, 'messageformat');
      }

      function getTimeStamp(job) {
        if (showClientResult) {
          return moment(getClientModerationResult(job).timestamp);
        }

        return moment(job.ad.current.timestamp);
      }

      function getPreviousQueue(job) {
        let prevQueueName = '';
        _.forEach(_.get(job, 'ad.history').slice(1), (historyItem)=> {
          const [queueName] = getCurrentQueueNameAndId(historyItem);
          if(queueName) {
            prevQueueName = queueName;
            return false;
          }
        });
        return prevQueueName;
      }

      function getText(status) {
        const domain = _.get(job, 'ad.current.actor.domain');// agent OR processor OR client
        const agent = _.get(job, 'ad.current.actor.id');
        const client = _.get(clientModerationResult, 'actorId');
        const whoMadeDecision = (domain === 'agent' && agent) || (domain === 'client' && client);
        const byWho = whoMadeDecision ? ` by ${whoMadeDecision}`: '';
        const refusalReason = getRefusalReasons(job);
        switch (status) {
        case('no decision'):
          return tFilter('ITEM.DECISION_SUMMARY.NO_DECISION_TEXT', {
            BY_WHO: byWho,
          }, 'messageformat');
        case('approved'): case('refused'):
          return tFilter('ITEM.DECISION_SUMMARY.DECISION_MADE_BY_WHOM_TEXT', {
            DECISION_MADE: hyphenizeFilter(getStatus(job)),
            DOMAIN: domain,
            BY_WHO: byWho,
            REFUSAL_REASON: refusalReason ? ` (${refusalReason})`: ''
          }, 'messageformat');
        case('reviewable'):
          return tFilter('ITEM.DECISION_SUMMARY.REVIEWABLE_TEXT', {QUEUE: job.currentQueueName? ' ' + job.currentQueueName : ''}, 'messageformat');
        case('deferred'):
          return tFilter('ITEM.DECISION_SUMMARY.DEFERRED_TEXT',
            {
              OLD_QUEUE: getPreviousQueue(job),
              NEW_QUEUE: job.currentQueueName,
              BY_WHO: byWho
            },
            'messageformat');
        case('new'):
          return tFilter('ITEM.DECISION_SUMMARY.PROCESSING_TEXT');
        }
      }

      function getTimeSinceEntered() {
        return durationFilter(moment().diff(timeEntered), false, getDurationText);
      }

      function getDecisionFromNow(lastHistoryTimestampMoment) {
        return getIntervalFromNow(new Date(lastHistoryTimestampMoment).valueOf());
      }

      return {
        status,
        getTimeSinceEntered,
        getDecisionFromNow,
        timestamp,
        hasDecision,
        iconName: icons[status],
        statusActorText: getText(status),
        timeToDecision: hasDecision ? getTimeToDecision(job) : '',
        timeWaiting: !hasDecision ? tFilter('ITEM.DECISION_SUMMARY.TIME_WAITING', {STATUS: status, TIME: timeSinceEntered}, 'messageformat'): '',
        timeWaitingTooltip: !hasDecision ? tFilter('ITEM.DECISION_SUMMARY.TIME_WAITING_TOOLTIP', {TIME: timeEnteredFormatted, TIMEZONE: timeZone}, 'messageformat'): '',
        decisionFromNow: hasDecision ? tFilter('ITEM.DECISION_SUMMARY.DECISION_FROM_NOW', {TIME_NUM: timeNum,  TIME_UNIT: timeUnit}, 'messageformat') : '',
        decisionFromNowTooltip: hasDecision ? tFilter('ITEM.DECISION_SUMMARY.TIMESTAMP_TOOLTIP', {TIMESTAMP: timestampFormatted, TIMEZONE: timeZone}, 'messageformat'): ''
      };
    };
  });
})();
