(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    $stateProvider.state('app.createTeam', {
      url: '/create-team',
      data: {
        title: 'PAGE_TITLE.CREATE_TEAM',
        // transitionPolicy: {
        //   requireAuth: false,
        //   allowLoggedIn: false
        // }
      },
      params: {
        email: '',
        teamname: '',
        token: '',
        domain: 'domain',
        inviteParams: {
          email: '',
          teamname: '',
          token: '',
          domain: ''
        }
      },
      views: {
        'main@': {
          template: require('@partial/start/start.html')
        },

        'content@app.createTeam': {
          template: require('./createTeam.html'),
          controller: 'CreateTeamCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
