import Queues from '@/react-app/features/Queues';
import store from '@store';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

(function() {
  'use strict';


  angular
    .module('eva2-angular')
    .controller('manualQueuesCtrl', manualQueuesCtrl);

  
  function manualQueuesCtrl($stateParams, UserPermissionManager, $timeout, $state, CurrentUser, $scope) {
    'ngInject';

    const vm = this;
    const root = createRoot(document.querySelector('#queuesPage'));

    vm.domain = $stateParams.domain;

    const renderQueues = () => {
      $timeout(() => {
        root.render(
          <Provider store={store}>
            <Queues domain={vm.domain}
              angularJsDependencies={
                {
                  href: $state.href,
                  userPermissionManager: UserPermissionManager,
                  go: $state.go,
                }
              }
              currentUser={CurrentUser}
            />
          </Provider>
        );
      });
    };

    vm.$onInit = function() {
      renderQueues();
    };

    $scope.$on('$destroy', ()=> {
      root.unmount();
    });
  }
})();
