"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routeMapping = void 0;
// changePassword and mfa does not need a permission to get access to that's why they are not in SettingsRoutesSuffixWithPermissions
exports.routeMapping = {
    'change-password': 'changePassword',
    'team-general': 'teamGeneral',
    'team-members': 'teamMembers',
    'manual-moderation-settings': 'manualModerationSettings',
    'field-editing': 'fieldEditing',
    orchestration: 'orchestration',
    'api-integration': 'apiIntegration',
    'right-to-be-forgotten': 'deleteUserData',
    'data-storing-options': 'dataStoringOptions',
    'delete-all-content': 'deleteAllContent',
    security: 'security',
    mfa: 'mfa',
};
