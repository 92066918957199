import { createRoot } from 'react-dom/client';
import React from 'react';
import store from '@store';
import { Provider } from 'react-redux';
import { Settings } from '@features/SettingsPage';
import _ from 'lodash';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider'];

  function appConfig($stateProvider) {
    
    $stateProvider.state('app.assignment.settings', {
      url: '/settings',
      abstract: true,
      views: {
        'content@app': {
          template: require('@partial/layouts/layouts2.html')
        }
      }
    });


    $stateProvider.state('app.assignment.settings.react', {
      url: '/:component',
      views: {
        'content@app': {
          template: '<div id="settings-main"></div>',
          controller: ($stateParams,
            $state,
            ApiService,
            CurrentUser,
            $rootScope,
            DeleteAllContentJobsManager,
            EvaLogger,
            UserPermissionManager) => {

            const root = createRoot(document.querySelector('#settings-main'));

            function siteNameChanged() {
              ApiService.getUser().then((data) => {
                CurrentUser.updateDomains(_.get(data, 'authenticated.domains'));
                $rootScope.$emit('$teamNameChanged');
              });
            }

            function setShowDeleteAllContentNotice(show) {
              $rootScope.showDeleteAllContentNotice = show;
            }

            root.render(<Provider store={store}>
              <Settings
                siteNameChanged={siteNameChanged}
                component={$stateParams.component}
                domain={$stateParams.domain}
                currentUserEmail={CurrentUser.email}
                currentTeamName={CurrentUser.getAssignmentNameFromDomain($stateParams.domain)}
                angularJsDependencies={{
                  href: $state.href,
                  deleteAllContent: {
                    DeleteAllContentJobsManager, setShowDeleteAllContentNotice
                  },
                  EvaLogger,
                  userPermissionManager: UserPermissionManager
                }} />
            </Provider>);
          },
          controllerAs: 'vm'
        }
      }
    });

    
  }
})();
