"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRegExp = exports.isValidRegExp = void 0;
var looksLikeRegExp = /^\/(.+)\/([a-zA-Z]*)$/;
var validRegExp = /^\/(.+)\/((i|m|g){0,3})$/;
var isValidRegExp = function (regExp) {
    // Check general syntax with modifiers
    if (regExp.match(validRegExp) === null) {
        return false;
    }
    // Check that regex is actually valid
    try {
        var regExpMatches = regExp.match(validRegExp);
        if (!regExpMatches) {
            return false;
        }
        var regExpMatchPart = regExpMatches[1];
        var regExpFlagPart = regExpMatches[2];
        var regexp = new RegExp(regExpMatchPart, regExpFlagPart);
        ''.match(regexp);
        return true;
    }
    catch (err) {
        return false;
    }
};
exports.isValidRegExp = isValidRegExp;
var isRegExp = function (regExp) {
    return regExp.match(looksLikeRegExp) !== null;
};
exports.isRegExp = isRegExp;
