"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFocusEffect = void 0;
var react_1 = require("react");
var useFocusEffect = function (isOpen, focusFilterId, inputRefs, implioFilterGroups, setExpandedFilterGroups) {
    (0, react_1.useEffect)(function () {
        var timeoutId = null;
        if (isOpen && focusFilterId) {
            timeoutId = setTimeout(function () {
                var _a;
                var inputRef = (_a = inputRefs[focusFilterId]) === null || _a === void 0 ? void 0 : _a.current;
                if (inputRef) {
                    inputRef.focus();
                    inputRef.click();
                }
                var groupToExpand = implioFilterGroups.findIndex(function (group) {
                    return group.filters.find(function (filter) { return filter.id === focusFilterId; });
                });
                setExpandedFilterGroups([groupToExpand]);
            }, 300); // Assuming FOCUS_DELAY is 300ms
        }
        return function () {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isOpen, focusFilterId]);
};
exports.useFocusEffect = useFocusEffect;
