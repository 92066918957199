"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.htmlizerService = void 0;
var linkify = __importStar(require("linkifyjs"));
var htmlizerService = function (data, EvaLogger, context) {
    return new Promise(function (resolve, reject) {
        if (!data || !data.text) {
            resolve([{ value: '', type: 'text', isLink: false, highlighted: '' }]);
        }
        else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            var worker_1 = new Worker(new URL('./worker.js', import.meta.url));
            var isDone_1 = false;
            var linkList = linkify.find(data.text);
            var dataLinkifyParts = __assign(__assign({}, data), { parts: linkList });
            worker_1.postMessage(dataLinkifyParts); // Send data to our worker.
            setTimeout(function () {
                worker_1.terminate();
                if (!isDone_1) {
                    EvaLogger.captureMessage("ERROR HighlightService TIMEOUT for item ".concat(context.itemId, " on ").concat(context.domain, " for ").concat(context.type, " rules ").concat(data.rules.map(function (rule) { return rule.rule.concat(','); })));
                    // When the worker take more than 1s to process the data, we return the data without highlight
                    // It let us avoid the infinite loading
                    resolve([{ value: data.text || '', type: 'text', isLink: false, highlighted: data.text || '' }]);
                }
            }, 15000);
            worker_1.addEventListener('message', function (e) {
                isDone_1 = true;
                worker_1.terminate();
                resolve(e.data.htmlPart);
            }, false);
            worker_1.addEventListener('error', function (e) {
                EvaLogger.captureMessage("ERROR highlightService ERROR for item ".concat(context.itemId, " on ").concat(context.domain, " for ").concat(context.type, " with error ").concat(e.message));
                console.error('ERROR', e);
                isDone_1 = true;
                resolve([{ value: data.text || '', type: 'text', isLink: false, highlighted: data.text || '' }]);
                worker_1.terminate();
                reject(e);
            });
        }
    });
};
exports.htmlizerService = htmlizerService;
