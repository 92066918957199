
(function() {
  'use strict';
  angular
    .module('eva2-angular')
    .component('evaAuth0Notice', {
      bindings: {
      },
      controllerAs: 'vm',
      template: require('./auth0Notice.html'),
    });
})();

