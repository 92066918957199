"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryDecodeURIComponent = exports.mapRules = exports.mapRefusals = exports.mapFeedbacks = exports.sortItems = void 0;
var sortItems = function (items) { return items.sort(function (a, b) { return a.text.localeCompare(b.text); }); };
exports.sortItems = sortItems;
var mapFeedbacks = function (feedbacks) {
    return feedbacks
        ? feedbacks.map(function (feedback) { return ({
            id: feedback.feedbackName,
            text: feedback.feedbackName,
        }); })
        : [];
};
exports.mapFeedbacks = mapFeedbacks;
var mapRefusals = function (refusalReasons) {
    return refusalReasons
        ? refusalReasons.results.map(function (reason) { return ({
            id: reason.name,
            text: reason.name,
        }); })
        : [];
};
exports.mapRefusals = mapRefusals;
var mapRules = function (rules) {
    return rules.map(function (rule) { return ({
        // we need to send the rule name https://help.besedo.com/search-query-syntax
        id: rule.name,
        text: rule.name,
    }); });
};
exports.mapRules = mapRules;
var tryDecodeURIComponent = function (encodedURIComponent) {
    try {
        var decodedURIComponent = decodeURIComponent(encodedURIComponent);
        return decodedURIComponent;
    }
    catch (error) {
        // If an error occurs during execution
        // Check if the error is a URIError
        if (error instanceof URIError) {
            // If the error is a URIError, log the error message with 'URIError' prefix
            console.error('URIError:', error.message);
        }
        else {
            // If the error is not a URIError, log the error message with 'Error' prefix
            console.error('Error:', JSON.stringify(error));
        }
        return encodedURIComponent;
    }
};
exports.tryDecodeURIComponent = tryDecodeURIComponent;
